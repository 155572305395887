import { render, staticRenderFns } from "./PanelRecommendations.vue?vue&type=template&id=f711e1fa&scoped=true"
import script from "./PanelRecommendations.vue?vue&type=script&lang=js"
export * from "./PanelRecommendations.vue?vue&type=script&lang=js"
import style0 from "./PanelRecommendations.vue?vue&type=style&index=0&id=f711e1fa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f711e1fa",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton} from 'bootstrap-vue'
    installComponents(component, {BButton})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-tooltip': VBTooltip})
    

export default component.exports