<template>
	<div>
		<div class="my-4" :class="classWrapper">
			<div>
				<div class="row mb-3">
					<div class="col-12">
						<div class="title text-lg-center mb-0">
							Персональная подборка контента по интересующим вас темам
						</div>
					</div>
				</div>
				<div id="__recommendations-panel" class="recommend-header">
					<div class="row recommend-header--wrapper">
						<div class="col-12 col-lg-4">
							<!-- <div class="title mb-lg-0 mb-3 text-lg-center ">
								Выберите,<br />
								что интересно вам
							</div> -->
						</div>
						<div class="col-12 col-lg-4 d-flex justify-content-lg-center">
							<b-button
								variant="primary"
								class="recommend-header--button mt-2 mt-md-0"
								aria-controls="collapse-recommendations"
								@click="visible = !visible"
							>
								<span
									class="icon icon-tags title-normal mb-0"
								/>
								<span class="title mb-0 ml-2">Выбрать темы</span>
							</b-button>
						</div>
					</div>
				</div>
				<div class="col-12">
					<TagsOfRecommendation
						v-model="selectedTags"
						:is-visible="visible"
						@setVisible="(value) => visible = value"
					/>
				</div>
				<div v-if="isShowRecommendations" class="my-3">
					<div class="row flex-column flex-sm-row align-items-center justify-content-center">
						<div class="col-12 col-md-9 col-lg-5">
							<div class="recommend-tabs">
								<b-button
									v-for="tab in tabs"
									:key="tab.id"
									:class="activeTab === tab.id ? 'recommend-tabs__button--active' : 'recommend-tabs__button'"
									@click="selectTab(tab.id)"
								>
									{{ tab.text }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isShowRecommendations">
				<div v-if="recommendationsData && recommendationsData.data.length">
					<div class="recommend-wrapper-card">
						<div
							v-for="(item, index) in recommendationsData.data"
							:key="index"
							class="recommend-card"
						>
							<div
								class="recommend-card--item"
								:style="'background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)), url(' + item.top_cover_image_default.src + ')'"
							>
								<div>
									<div class="small mr-2">
										{{ item.typology_title }}
									</div>
									<div class="title">
										{{ item.title }}
									</div>
									<!-- <div class="d-flex flex-row align-items-start mt-3">
										<div
											v-for="taxonomy in item.taxonomy_options.slice(0, 2)"
											:key="taxonomy.id"
											class="small mr-2"
										>
											{{ taxonomy.name_ru }}
										</div>
									</div> -->

								</div>
								<div>
									<div v-if="item.event_date" class="mb-4 d-flex align-items-center">
										{{ item.event_date | moment("DD MMMM YYYY") }}
										<a
											v-b-tooltip.hover.top="$t('AddCal')"
											:href="'//sk.skolkovo.ru/api/calendar/event/' + item.id"
											class="add_announce ml-2"
											aria-label="$t('AddCal')"
										/>
									</div>
									<nuxt-link
										v-if="item.recommendationType === 'UsefulContent'"
										:to="$url.media(item.slug, item.taxonomy_type_slug, {}, item.lang )"
										class="btn btn-primary"
									>
										Подробнее
									</nuxt-link>
									<nuxt-link
										v-if="item.recommendationType === 'Event'"
										:to="$url.event(item.slug, item.typology_slug)"
										class="btn btn-primary"
									>
										Подробнее
									</nuxt-link>
								</div>
							</div>
						</div>
					</div>
					<div class="row align-items-center">
						<button
							v-if="!isShowAllItems && !isLoading"
							type="button"
							class="btn btn-primary m-auto"
							@click.prevent="isShowAllItems = true"
						>
							{{ $t('showAll') }}
						</button>

						<intersect
							v-if="isShowAllItems"
							@enter="handlerPagination"
						>
							<div class="intersect" />
						</intersect>
					</div>
				</div>
				<div v-else class="col-12">
					<div class="title">
						По вашим предпочтениям ничего не нашлось
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import TagsOfRecommendation from '~/components/panels/PanelUserRecommendations/TagsOfRecommendation.vue';
import Intersect from 'vue-intersect';

export default {
	name: 'PanelRecommendations',
	components: {
		TagsOfRecommendation,
		Intersect,
	},
	props: {
		classWrapper: {
			type: String,
			default: 'container',
		},
		propActiveTab: {
			type: Number,
			default: -1,
		},
	},
	data() {
		return {
			visible: false,
			selectedTags: [],
			isAutocompleted: false,
			recommendationsData: null,
			page: 1,
			isShowAllItems: false,
			isLoading: false,
			isLoadingCreateRecommendations: false,
			tabs: [
				{
					id: -1,
					value: -1,
					text: 'Все',
				},
				{
					id: 1,
					value: 1,
					text: 'Программы',
				},
				{
					id: 0,
					value: 0,
					text: 'Мероприятия',
				},
				{
					id: 2,
					value: 2,
					text: 'Медиа',
				},
			],
			activeTab: -1,
			isShowRecommendations: false,
		};
	},
	watch: {
		activeTab: {
			handler(value) {
				this.getRecommendations(value);
				this.$emit('handlerChangeActiveTab', value);
			},
		},
		selectedTags: {
			handler() {
				this.isShowRecommendations = true;
				this.getRecommendations();
			},
		},
		propActiveTab: {
			handler(value) {
				this.activeTab = value;
			},
		},
	},
	methods: {
		...mapActions({
			showLoginPopup: 'authModal/showLogin',
		}),
		async getRecommendations(tab = this.activeTab) {
			this.page = 1;

			await this.$api.getUserRecommendations(this.getData(tab))
				.then((res) => {
					this.recommendationsData = res;
				});

			if (this.$auth.user) {
				const formData = new FormData();
				this.selectedTags.forEach((tag, index) => {
					formData.append(`values[user_recommendations][${index}]`, tag);
				});
				await this.$api.setUserProfileFields(formData);
			}
		},
		getData(tab) {
			let data = {};
			const typologies = tab;
			data = {
				filters: [
					...this.selectedTags.map((tag) => ({
						type: 'taxonomyOptions',
						value: tag,
					})),
				],
				typologies: typologies === -1 ? [0, 1, 2] : [typologies],
				page: this.page,
			};
			return data;
		},
		selectTab(tab) {
			this.page = 1;
			this.activeTab = tab;
			this.isShowAllItems = false;
		},
		async createRecommendations() {
			this.isLoadingCreateRecommendations = true;
			this.activeTab = -1;
			this.isShowAllItems = false;
			await this.getRecommendations();
			this.visible = false;
			this.isShowRecommendations = true;
			this.isLoadingCreateRecommendations = false;
		},
		async handlerPagination() {
			this.page += 1;
			this.isLoading = true;

			await this.$api.getUserRecommendations(this.getData(this.activeTab))
				.then((res) => {
					this.recommendationsData.data.push(...res.data);
					this.isLoading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '~/assets/styles/sk_variables';
@import "~/assets/styles/bootstrap/scss/functions";
@import "~/assets/styles/bootstrap/scss/variables";
@import "~/assets/styles/bootstrap/scss/mixins";

.profile {
	.recommend-header {
		border-radius: 0.5rem;
	}
	.recommend-card--item {
		border-radius: 0.5rem !important;
	}
}
.recommend-header {
    background-clip: content-box;
    background: $primary;
    color: white;
		border-radius: 0rem;
		overflow: hidden;

		&--wrapper {
			min-height: 100px;
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 2rem 1.5rem;
			background-image: url('/frontimg/recommendation_header.png');
			background-repeat: no-repeat;
			background-position: right -30% top 50%;
			background-size: 50%;

		@include media-breakpoint-up(md) {
			background-position: right 8% top 50%;
			background-size: 37%;
		}

		@include media-breakpoint-up(lg) {
			background-position: right 8% top 50%;
			background-size: 19%;
		}

		@include media-breakpoint-down(sm) {
			align-items: stretch;
			flex-direction: column;
			justify-content: space-between;
		}

}

	&--button {
		background-color: white;
		border-radius: 2rem;
		display: flex;
		align-items: center;
		color: $blue;

		.icon-plus {
			background-color: $blue;
			color: white;
			border-radius: 50%;
			width: 2rem;
			height: 2rem;
		}

		&:hover {
			color: white;
			background-color: transparent;
			border: 1px solid white;

			.icon-plus {
				background-color: white;
				color: $blue;
			}
		}

		@include media-breakpoint-down(sm) {
			color: white;
			background-color: transparent;
			border: 1px solid white;
			font-size: 0.7rem;

			.icon-plus {
				width: 1.3rem;
				height: 1.3rem;
				background-color: white;
				color: $blue;
			}
		}
	}

	&--select {
		border: 1px solid $border-color;
		color: black;
		padding: 0.375rem 1rem !important;

		option {
			color: black !important;
		}
	}
}

.recommend-tabs {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: $theme-lightblue;
	border-radius: 0rem;
	padding: 0.5rem;

	&__button {
		color: black;
		background-color: transparent;
		border-color: transparent;
		border-radius: 2rem;

		&--active {
			color: white;
			background-color: $blue;
			border-radius: 1rem;
		}

	}
	@include media-breakpoint-down(sm) {
		button {
			font-size: 0.8rem;
			font-weight: 500;
			padding: 0.375rem 0.45rem;
			border-radius: 1rem;
		}
	}
}

.recommend-wrapper-card {
	display: flex;
	flex-flow: row wrap;
	max-width: 4000px;
	justify-content: space-between;
	margin: 0 -0.7rem;

	@include media-breakpoint-down(xl) {
		max-width: 1752px;
	}

	@include media-breakpoint-down(md) {
		max-width: 744px;
	}

	@include media-breakpoint-down(sm) {
		max-width: 528px;
	}

	&:after {
		content: '';
		flex: auto;
	}

	.recommend-card {
		width: 25%;
		// min-width: 295px;
		padding: 0 0.7rem 1.4rem;

		@include media-breakpoint-down(md) {
			width: 50%;
			// min-width: 250px;
		}

		@include media-breakpoint-down(xs) {
			width: 100%;
		}

		&--item {
			padding: 1rem;
			height: 18rem;
			border-radius: 0rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			background-size: cover;
			color: white;

			@include media-breakpoint-down(xs) {
				height: 15rem;
			}

			.title {
				font-size: 1.3rem;
				line-height: 1.5rem;

				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				word-wrap: break-word;
				overflow: hidden;
				white-space: normal;
			}

			.small {
				font-size: 0.75rem;
			}

			.add_announce {
				position: relative;
				letter-spacing: 0;
				text-decoration: none;
				color: $control-link-color;

				margin-top: 4px;
				margin-right: 4px;

				&:hover {
					color: white;
				}

				&::before {
					content: "\e921";
					display: block;
					font-family: 'sk_icons';
					line-height: 1rem;
					font-size: 1.2rem;
				}
			}
		}
	}
}

.icon {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	margin-left: 0.5rem;
}

.button-setting {
	cursor: pointer;
}
</style>
